import IridescentImage from '../components/IridescentImage'
import { HeaderSection, Section } from '../components/Section'
import { Text, Link } from '@chakra-ui/react'
import Page from './Page'

const _bg = '#C4BDC9'
const partners = [
  { name: 'ezkl', href: 'https://ezkl.xyz' },
  { name: 'neoswap', href: 'https://neoswap.xyz/' },
  { name: 'cysic', href: 'http://cysic.xyz/' },
]

const textLink = ({ name, href }) => {
  return (
    <Link style={{ textDecorationLine: 'underline' }} href={href} isExternal>
      {name}
    </Link>
  )
}

const About = () => {
  return (
    <Page title="about" bg={_bg}>
      <HeaderSection
        style="white"
        bg={_bg}
        title="Be Part of the Decentralized AI Revolution"
        subtext="Our decentralized AI protocol is built for growth, enabling projects to access the benefits of AI without the restrictions of traditional compute infrastructure. As your AI tasks increase, you'll see improved efficiency and cost savings."
        img="/content/tiger.png"
        href="/team"
        isExternal={false}
        link_text={'Meet the team →'}
      ></HeaderSection>
      <Section
        bg="darkBg"
        style="light"
        subtext="Welcome to Inference Labs"
        title="Discover Our Protocol"
        text={
          'Learn about our state-of-the-art decentralized protocol for running AI compute workloads, designed with trustless neural network inference and zero-knowledge proofs at its core.'
        }
      >
        <IridescentImage p="8" src="/content/elk.png" />
      </Section>
      <Section
        bg="white"
        offset="center"
        style="dark"
        subtext="Why Choose Inference Labs"
        title="Integrate into our network"
        text={
          "Easily integrate Inference Labs' protocol into your existing infrastructure or start building new AI-powered applications with our decentralized protocol."
        }
      >
        <div>
          <Text>
            Our partners include {textLink(partners[0])}, {textLink(partners[1])}, and{' '}
            {textLink(partners[2])} just to name a few.
          </Text>
          <IridescentImage p="8" src="/content/wolf.png" />
        </div>
      </Section>
      <Section
        bg="darkBg"
        offset="right"
        style="light"
        subtext="Collaborate and Innovate"
        title="Scale and Succeed
        "
        text={
          "Leverage our protocol's scalability and efficiency to unlock new opportunities, reduce costs, and drive the success of your AI initiatives."
        }
      >
        <IridescentImage p="8" src="/content/honeybee.png" />
      </Section>
    </Page>
  )
}

export default About

import { Center, Link, Stack, Text } from '@chakra-ui/react'

const FooterHeader = ({ children }) => {
  return (
    <Text fontSize={'lg'} fontWeight={'bold'} color={'white'}>
      {children}
    </Text>
  )
}

const FooterLink = ({ href, children, isExternal = false }) => {
  return (
    <Link color={'white'} href={href} isExternal={isExternal}>
      {children}
    </Link>
  )
}

const FooterStack = ({ children, header }) => {
  return (
    <Stack height={'full'}>
      <FooterHeader>{header}</FooterHeader>
      {children}
    </Stack>
  )
}

const Footer = () => {
  return (
    <Stack
      alignItems={{ base: 'center', md: 'unset' }}
      p={{ base: '10', md: '20' }}
      paddingBottom="10"
    >
      <Stack direction={'row'} spacing={{ base: 4, sm: '24' }}>
        <FooterStack header={'Info'}>
          <FooterLink href={'/'}>Home</FooterLink>
          <FooterLink href={'/about'}>About</FooterLink>
          <FooterLink href={'/team'}>Team</FooterLink>
          <FooterLink href="https://docs.inferencelabs.com/resources/glossary" isExternal>
            Glossary
          </FooterLink>
          <FooterLink href={'/contact'}>Contact</FooterLink>
        </FooterStack>
        <FooterStack header={'Legal'}>
          <FooterLink href="https://docs.inferencelabs.com/resources/terms-of-service" isExternal>
            Terms of Service
          </FooterLink>
          <FooterLink href={'https://docs.inferencelabs.com/resources/privacy-policy'} isExternal>
            Privacy Policy
          </FooterLink>
        </FooterStack>
        <FooterStack header={'Follow Us'}>
          <FooterLink href={'https://x.com/inference_labs'}>X</FooterLink>
          {/* <FooterLink>Telegram</FooterLink> */}
        </FooterStack>
      </Stack>
      <Center paddingTop={'4'}>
        <Text color={'white'} textAlign={'center'}>
          © {new Date().getFullYear()} Inference Labs Inc.
          <br /> All rights reserved{' | '}
          <FooterLink href={'/contact'}>Contact Us</FooterLink>
        </Text>
      </Center>
    </Stack>
  )
}

export default Footer
